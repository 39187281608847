import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import formStyles from "../EqxForm/EqxForm.module.scss";
import styles from "./EqxSelect.module.scss";

const EqxSelect = ({
  className,
  defaultValue,
  inputProps,
  label,
  options,
  showEmptyOptions,
}) => {
  const { control } = useFormContext();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <FormControl
      className={classNames(formStyles.formField, styles.input, className)}
      style={{ width: "100%" }}
    >
      <InputLabel id={inputProps?.name ? inputProps.name : ""}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={inputProps && inputProps.name ? inputProps.name : label}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            aria-invalid={!!error}
            className={classNames(styles.input, className)}
            data-is="EqxSelect"
            defaultValue={defaultValue ? defaultValue : ""}
            error={!!error}
            fullWidth={true}
            inputProps={inputProps}
            label={label}
            labelId={inputProps?.name ? inputProps.name : ""}
            onChange={onChange}
            value={value ? value : ""}
            variant="standard"
          >
            {showEmptyOptions && (
              <MenuItem value="">
                <em>Select...</em>
              </MenuItem>
            )}
            {options &&
              options.map((option) => {
                const { fields } = option;

                const {
                  formFieldOptionName,
                  formFieldOptionValue,
                  facilityId,
                  webName,
                  club: clubs,
                  name,
                } = fields;

                let optionsElements;

                if (formFieldOptionValue || facilityId) {
                  optionsElements = (
                    <MenuItem
                      className={styles.menuItem}
                      key={formFieldOptionValue || facilityId}
                      value={formFieldOptionValue || facilityId}
                    >
                      {formFieldOptionName || webName}
                    </MenuItem>
                  );
                } else {
                  optionsElements = (
                    <>
                      <ListSubheader>{name}</ListSubheader>
                      {clubs &&
                        clubs.map((club) => (
                          <MenuItem
                            className={styles.menuItem}
                            key={club.fields.facilityId}
                            value={club.fields.facilityId}
                          >
                            {club.fields.name}
                          </MenuItem>
                        ))}
                    </>
                  );
                }
                return optionsElements;
              })}
          </Select>
        )}
        rules={{ required: true }}
      />
    </FormControl>
  );
};

EqxSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  showEmptyOptions: PropTypes.bool,
};

export default EqxSelect;
